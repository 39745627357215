import { createI18n } from 'vue-i18n'
import enLocale from '@/config/i18n_config/en.json';
import zhLocale from '@/config/i18n_config/zh.json';
import { appConfig } from '@/config/app.config.js';


// 获取浏览器的首选语言
const userLanguage = navigator.language;
// 如果用户语言以 'zh' 开头，则设置为中文，否则设置为英文
var locale = userLanguage.startsWith('zh') ? 'zh' : 'en';
// locale = 'zh'

// 如果 LANG_ONLY_ENGLISH 为 false，则设置为英文
if (appConfig.LANG_ONLY_ENGLISH) {
    locale = 'en'
}

const i18n = createI18n({
    legacy         : false,   // 使用 Composition API
    globalInjection: true,    // 全局注入语言
    locale         : locale,  // 设置语言
    messages       : {
        en: enLocale,
        zh: zhLocale
    }
});


export default i18n
