// backend API Version
let APP_NAME = "test"
let API_VERSION = "v1"
let BASE_URL_PROD = "https://test-api.megaplus.co.nz"
let BASE_URL_DEV = "https://test-api.megaplus.co.nz"

var UPLOAD_URL = ""
var API_LOADING_DURATION = 100
// umami config
let UMAMI_WEBSITE_ID = "1ec1aa78-fb85-43e7-8588-d784717b3f14"
let UMAMI_SCRIPT_SRC = "https://umami.megabit.co.nz/script.js"

// 检测当前环境, 并初始化 API baseURL
if (window.location.href.includes('192.168') || window.location.href.includes('localhost')) {
    UPLOAD_URL = BASE_URL_DEV + "/static/upload/"
} else {
    UPLOAD_URL = BASE_URL_PROD + "/static/upload/"
}

module.exports = {
    APP_NAME: APP_NAME,
    API_PROD: BASE_URL_PROD + "/api/" + API_VERSION,
    API_DEV: BASE_URL_DEV + "/api/" + API_VERSION,
    BACKEND_SECRET_KEY: "dc3dfeb3e55147ba9fdcee7dd6fcdbf7",
    UPLOAD_URL: UPLOAD_URL,
    API_LOADING_DURATION: API_LOADING_DURATION,
    UMAMI_SCRIPT_SRC: UMAMI_SCRIPT_SRC,
    UMAMI_WEBSITE_ID: UMAMI_WEBSITE_ID,
    SENTRY_URL_DEV: "https://663ee442fe2e4124957536b34290083f@sentry.megabit.co.nz/4",
    SENTRY_URL_PROD: "https://accc0ce41f92450fad8ca02687082053@sentry.megabit.co.nz/5",
}
