// 语言 - 开启中文
let tmp_LANG_ONLY_ENGLISH = false
// 订单 - 开启周末下单
let tmp_APP_ENABLE_ORDER_WEEKEND = true
// 订单 - 开启当日最迟下单时间
let tmp_APP_ENABLE_ORDER_BEFORE_TIME = "00:00"

export const appConfig = {
    LANG_ONLY_ENGLISH: tmp_LANG_ONLY_ENGLISH,
    APP_ENABLE_ORDER_WEEKEND: tmp_APP_ENABLE_ORDER_WEEKEND,
    APP_ENABLE_ORDER_BEFORE_TIME: tmp_APP_ENABLE_ORDER_BEFORE_TIME
}
