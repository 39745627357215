<template>
    <div class="FooterCom">
        <span>
            Frontend:v{{ pack.version }} | Backend:{{ backendVersion.version }}
        </span>
        <br />
        &copy; 2025 All rights reserved. <br>
        Powered by MEGABIT.CO.NZ
    </div>
</template>
<script setup>
import { onMounted, ref } from "vue"
import pack from "../../../package.json"
import { fetchBackendVersionService } from "../../services/commonService"

// MARK - Props
// ************************************************************************
var backendVersion = ref({
    'version': ''
})

// MARK - LIFE CYCLE
// ************************************************************************
onMounted(() => {
    fetchBackendVersionService().then((res) => {
        backendVersion.value = res.data
    })
})

// MARK - Methods
// ************************************************************************
</script>
<style>
.FooterCom {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 12px;
    color: #666;
    padding-top: 32px;
    padding-bottom: 64px;
}
</style>
