<template>
    <div class="LangSwitchCom">
        <van-floating-bubble
            @click="onLanguageChange"
            axis="clock"
            v-model:offset="offset"
            style="background-color: transparent"
            v-if="!appConfig.LANG_ONLY_ENGLISH"
        >
            <!-- :style="{ 'background-color': langColor }" -->
            <template #default>
                <Icon :icon="langIcon" style="font-size: 48px"></Icon>
            </template>
        </van-floating-bubble>
    </div>
</template>
<script setup>
import { useI18n } from "vue-i18n"
import { onMounted, ref } from "vue"
import { Icon } from "@iconify/vue"
import { appConfig } from "@/config/app.config"


// MARK - Props
// ************************************************************************
const { locale } = useI18n()
// 设置浮动气泡的偏移量
const offset = ref({ x: -4, y: 4 })
// 设置浮动气泡的文本
const lang = ref(locale.value.includes("en") ? "中文" : "EN")
// 设置浮动气泡的图标
const langIcon = ref(locale.value.includes("en") ? "icon-park:chinese" : "icon-park:english")
const langColor = ref(locale.value.includes("en") ? "red" : "blue") // 设置浮动气泡的颜色

// MARK - LIFE CYCLE
// ************************************************************************
onMounted(() => {
})

// MARK - Methods
// ************************************************************************
const onLanguageChange = () => {
    console.log("onLanguageChange")
    console.log(locale)
    locale.value = locale.value.includes("en") ? "zh-CN" : "en-US"
    lang.value = locale.value.includes("en") ? "中文" : "EN"
    langColor.value = locale.value.includes("en") ? "red" : "blue"
    langIcon.value = locale.value.includes("en") ? "icon-park:chinese" : "icon-park:english"
}
</script>
<style>
.LangSwitchCom {
    width: 100%;
    height: 100%;
}
</style>
