import axios from "axios"
import qs from "qs"
import md5 from "js-md5"
import { useStore } from "vuex";
import { colog } from "colog-node";
import { showLoadingToast } from "vant";
import i18n from "@/i18n";
import store from "@/store";

import config from "@/config/api.config.js"
import { cNotiError, cNotiSuccess } from "@/utils/cNotification.js"


// MARK -
// ************************************************************************
const { t } = i18n.global


// import storage from "store"
// import { ACCESS_TOKEN } from "@/store/mutation-types"

// MARK - Global config
// ************************************************************************
// 检测当前环境, 并初始化 API baseURL
if (window.location.href.includes('192.168') || window.location.href.includes('localhost')) {
    axios.defaults.baseURL = config.API_DEV
} else {
    axios.defaults.baseURL = config.API_PROD
}

// MARK - CONSTANT
// ************************************************************************
// json格式请求头
const headerJSON = {
    "Content-Type": "application/json;charset=UTF-8"
}
// FormData格式请求头
const headerFormData = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
}
// Multi格式请求头
const headerFormMulti = {
    "Content-Type": "multipart/form-data"
}

// MARK - Request 拦截器
// ************************************************************************
axios.interceptors.request.use(
    config => {
        // 给请求添加请求时间
        // if (config.url.indexOf('?') !== -1) {
        //     config.url += `&t=${new Date().getTime()}`;
        // } else {
        //     config.url += `?t=${new Date().getTime()}`;
        // }
        // `transformRequest` 允许在向服务器发送前，修改请求数据
        // 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
        // 后面数组中的函数必须返回一个字符串，或 ArrayBuffer，或 Stream
        // config.transformRequest = [(data, headers) => {
        //     return qs.stringify(data, {
        //         allowDots: true
        //     })
        // }];

        // Header 请求添加token
        let token = store.getters.getToken
        config.headers["Authorization"] = "MERP " + token

        if ("post" === config.method) {
            if (typeof str === "string") {
                // POST JSON数据 MD5计算, 插入headers
                config.headers["Md5"] = md5(config.data)
            }
        }

        // `paramsSerializer` 是一个负责 `params` 序列化的函数
        config.paramsSerializer = params => {
            return qs.stringify(params, {
                arrayFormat: "repeat"
            })
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// MARK - Response 拦截器
// ************************************************************************
axios.interceptors.response.use(
    response => {
        // 👌 网络请求成功
        const { data, status } = response
        let business_code = data.code // 业务状态码
        let business_data = data.data // 业务数据
        let data_md5 = data.md5 // 数据md5

        // : data数据 MD5值 校验
        const business_data_json = JSON.stringify(business_data)
        const business_data_md5 = md5(business_data_json)
        // console.log(business_data_json)
        // console.log(business_data_md5)
        // console.log(data_md5)

        // if (business_data_md5 !== data_md5) {
        //     // TODO: 开启MD5校验
        //     cNotiError("error MD5")
        // }

        // 业务 status code === 0, 数据获取成功
        if (response.data.code === 0) {
            // 返回 response 给callback
            return response
        }

        // 业务 status code !== 0
        if (response.data.code !== 0) {
            // 后端处理异常, 抛出提示给客户
            switch (response.data.code) {
                // error [103] - ERROR_REQUEST_DATA_FORMAT
                case 103:
                    cNotiError("hint.ERROR_REQUEST_DATA_FORMAT")
                    break
                // error [105] - ERROR_VERIFICATION
                case 105:
                    cNotiError("Verification code doesn't match, please check your code!")
                    break
                // error [106] - ERROR_USER_EXIST
                case 106:
                    cNotiError("This username already exists!")
                    break
                // error [107] - ERROR_USER_NOT_EXIST
                case 107:
                    cNotiError("This username not exists!")
                    break
                // error [108] - ERROR_PASSWD
                case 108:
                    cNotiError("Password error!")
                    break
                // error [150] - ERROR_DATA_EXIST
                case 150:
                    cNotiError("This data already exists!")
                    break
                // error [402] - ERROR_BUSINESS_TOKEN
                case 402:
                    // 强制登出
                    store.dispatch("Logout")
                    window.location.reload()
                    break
                // error [9130] - ERROR_BUSINESS_PAYMENT_REMAINING
                case 9130:
                    cNotiError("The payment amount is insufficient")
                    break
                // error [9020] - ERROR_BUSINESS_FILE_FORMAT
                case 9020:
                    cNotiError("Upload file format error, please try another file!")
                    break
                default:
                    cNotiError("hint.errorRequest")
                    break
            }
            return Promise.reject(response)
        }
        // return Promise.reject(response)
    },
    error => {
        // ❌ 网络请求错误
        // colog.error(error)
        switch (error.response.status) {
            // error [401] - token无权限
            // error [402] - token过期
            case 401, 402:
                // 强制登出
                store.dispatch("Logout")
                window.location.reload()
                break
                // Vue.$store.dispatch("Logout").then(() => {
                //     setTimeout(() => {
                //         Vue.$router.push({ name: 'login' })
        //         //     }, 100)
        //         // })
        //         break
        //     // error [429] - 访问太快
        //     // 提醒客户访问过快
        //     case 429:
        //         cNotiError("hint.errorTooFast")
        //         break
        //     case 403:
            default:
                cNotiError("You don't have the permission!")
                break
        }
        return Promise.reject(error)
    }
)

// MARK - GET 请求
// ************************************************************************
/**
 * @func GET请求
 *
 * @param {String} url 请求URL地址
 * @param {Object} params 请求data参数
 * @returns
 */
export function get(url, params) {
    // const store = useStore()
    // if (store) store.commit('showLoading')
    showLoadingToast({
        message: 'loading...',
        forbidClick: true,
        duration: 0
    });
    // 判断是否含有params
    if (params) {
        url = url + "?"
    }
    // 拼接params
    for (var key in params) {
        var val = params[key]
        url = url + "&" + key + "=" + val
    }
    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: "get",
        })
            .then(function(response) {
                // setTimeout(() => {
                    // if (store) store.commit('dismissLoading')
                // }, config.API_LOADING_DURATION);
                showLoadingToast({
                    message: t('common.noti.loading'),
                    forbidClick: true,
                    duration: config.API_LOADING_DURATION
                });
                resolve(response.data)
            })
            .catch(function(error) {
                colog.debug(error)
                showLoadingToast({
                    message: t('common.noti.loading'),
                    forbidClick: true,
                    duration: config.API_LOADING_DURATION
                });
                reject(error)
            })
    })
}

// MARK - POST 请求
// ************************************************************************
/**
 * @func POST请求
 *
 * @param {String} url 请求URL地址
 * @param {Object} params 请求data参数
 * @param {Boolean} json 是否采用JSON请求
 * @returns
 */
export function post(url, params, json = false) {
    if (store) store.commit('showLoading')
    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: "post",
            data: json ? JSON.stringify(params) : qs.stringify(params),
            headers: json ? headerJSON : headerFormData
        })
            .then(function(response) {
                setTimeout(() => {
                    if (store) store.commit('dismissLoading')
                }, config.API_LOADING_DURATION);
                resolve(response.data)
            })
            .catch(function(error) {
                setTimeout(() => {
                    if (store) store.commit('dismissLoading')
                }, config.API_LOADING_DURATION);
                reject(error)
            })
    })
}

// MARK - POST 请求 upload
// ************************************************************************
/**
 * @func POST请求
 *
 * @param {String} url 请求URL地址
 * @param {Object} data 请求data参数
 * @param {Boolean} json 是否采用JSON请求
 * @returns
 */
export function upload(url, file) {
    if (store) store.commit('showLoading')
    let data = new FormData() // 创建form对象
    data.append("file", file)
    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: "post",
            data: data,
            headers: headerFormMulti
        })
            .then(function(response) {
                setTimeout(() => {
                    if (store) store.commit('dismissLoading')
                    store.commit('dismissLoading')
                }, config.API_LOADING_DURATION);
                resolve(response.data)
            })
            .catch(function(error) {
                setTimeout(() => {
                    if (store) store.commit('dismissLoading')
                }, config.API_LOADING_DURATION);
                reject(error)
            })
    })
}
