import { createRouter, createWebHistory, createWebHashHistory } from "vue-router"
import { useStore } from "vuex"

const routes = [
    {
        path: "/",
        name: "HomeView",
        component: () => import("../views/home/HomeView.vue"),
    },
    {
        path: "/category",
        name: "CategoryView",
        component: () => import("../views/category/CategoryView.vue"),
    },
    {
        path: "/cart",
        name: "CartView",
        component: () => import("../views/cart/CartView.vue"),
    },
    {
        path: "/checkout",
        name: "CheckoutView",
        meta: { requiresAuth: true },
        component: () => import("../views/cart/CheckoutView.vue"),
    },
    {
        path: "/checkout/pickup",
        name: "PickupView",
        meta: { requiresAuth: true },
        component: () => import("../views/cart/PickupView.vue"),
    },
    {
        path: "/checkout/chooseKids",
        name: "ChooseKidsView",
        meta: { requiresAuth: true },
        component: () => import("../views/cart/ChooseKidsView.vue"),
    },
    {
        path: "/checkout/payment",
        name: "PaymentView",
        meta: { requiresAuth: true },
        component: () => import("../views/cart/PaymentView.vue"),
    },
    {
        path: "/checkout/payment/credit-card",
        name: "CreditCardView",
        meta: { requiresAuth: true },
        component: () => import("../views/cart/CreditCardView.vue"),
    },
    {
        path: "/checkout/payment/bank-transfer",
        name: "BankTransferView",
        meta: { requiresAuth: true },
        component: () => import("../views/cart/BankTransferView.vue"),
    },
    {
        path: "/checkout/payment/wechat",
        name: "WeChatPayView",
        meta: { requiresAuth: true },
        component: () => import("../views/cart/WeChatPayView.vue"),
    },
    {
        path: "/checkout/payment/alipay",
        name: "AliPayView",
        meta: { requiresAuth: true },
        component: () => import("../views/cart/AliPayView.vue"),
    },
    {
        path: "/checkout/payment/store-credit",
        name: "StoreCreditView",
        meta: { requiresAuth: true },
        component: () => import("../views/cart/StoreCreditView.vue"),
    },
    {
        path: "/checkout/result",
        name: "ResultView",
        meta: { requiresAuth: true },
        component: () => import("../views/cart/ResultView.vue"),
    },
    {
        path: "/my",
        name: "MyView",
        component: () => import("../views/my/MyView.vue"),
    },
    {
        path: "/my/orders",
        name: "MyOrdersView",
        meta: { requiresAuth: true },
        component: () => import("../views/my/MyOrdersView.vue"),
    },
    {
        path: "/my/kids",
        name: "MyKidsView",
        meta: { requiresAuth: true },
        component: () => import("../views/my/MyKidsView.vue"),
    },
    {
        path: "/my/kids/edit/:id",
        name: "EditKidView",
        meta: { requiresAuth: true },
        component: () => import("../views/my/EditKidView.vue"),
    },
    {
        path: "/my/profile",
        name: "MyProfileView",
        meta: { requiresAuth: true },
        component: () => import("../views/my/MyProfileView.vue"),
    },
    {
        path: "/login",
        name: "LoginView",
        component: () => import("../views/auth/LoginView.vue"),
    },
    {
        path: "/register",
        name: "RegisterView",
        component: () => import("../views/auth/RegisterView.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFoundView",
        component: () => import("../views/404/NotFoundView.vue"),
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

// MARK - 路由守卫
let lastPosition = 0
// ************************************************************************
router.beforeEach((to, from, next) => {
    const store = useStore()
    // 解决刷新后页面不会回到顶部的问题
    document.body.scrollTop = 0 // firefox
    document.documentElement.scrollTop = 0 // safari
    window.pageYOffset = 0 // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁

    // 获取用户登录状态
    const token = store.getters.getToken
    if (to.meta.requiresAuth && token.length < 1) {
        next({ name: "LoginView" })
        return
    } else {
        // 获取用户信息
        if (token.length > 0) {
            store.dispatch("FetchUserInfo")
        }
        next()
    }
})

router.afterEach((to, from) => {
    const store = useStore()
    const curPosition = window.history.state.position
    if (curPosition > lastPosition) {
        store.commit("addPageDepth")
    }
    if (curPosition < lastPosition) {
        store.commit("page/reducePageDepth")
    }
    // colog.debug("history position => " + window.history.state.position)
})

export default router
