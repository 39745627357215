import { get, post } from '@/utils/request.js'
import { commonApi } from './base/urlConfig.js'

/**
 * @func 获取后端版本号
 *
 */
export function fetchBackendVersionService() {
    var url = commonApi.FetchBackendVersion
    return get(url)
}


export function translateContentService(content) {
    var url = commonApi.translateContent + content + "/"
    return get(url, null, true)
}
