<template>
    <div class="TabbarCom" v-if="!store.getters.isFullScreen">
        <van-tabbar v-model="active" @change="onTabbarChange" :active-color="tabbarColor">
            <van-tabbar-item
                v-for="tab in tabList"
                :key="tab.name"
                replace
                :name="tab.name"
                :badge="tab.name === 'Cart' ? cartItemCount : ''"
                :icon="tab.icon"
            >
                {{ t(tab.title) }}
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script setup>
import { useRouter } from "vue-router"
import { computed, ref } from "vue"
import { showToast } from "vant"
import { useStore } from "vuex"
import i18n from "@/i18n"

// MARK - Props
// ************************************************************************
const { t } = i18n.global
const store = useStore()
const router = useRouter()
const active = computed(() => {
    console.log("tabbar active ->" + store.getters.getTabbarIndex)
    return store.getters.getTabbarIndex
})
// const tabbarColor = ref("#4540ff")
const tabbarColor = ref("#4187f2") // 高亮颜色
const cartItemCount = computed(() => {
    return store.getters.getCartQty
})

const tabList = [
    {
        name: "Home",
        title: "cmpt.tabbar.home",
        icon: "home-o",
    },
    // {
    //     name: "Category",
    //     title: "cmpt.tabbar.category",
    //     icon: "apps-o",
    // },
    {
        name: "Cart",
        title: "cmpt.tabbar.cart",
        icon: "cart",
    },
    {
        name: "My",
        title: "cmpt.tabbar.my",
        icon: "user",
    },
]

// MARK - Methods
// ************************************************************************
/**
 * Handles the tab bar change event.
 *
 * @param {number} index - The index of the tab bar item that was clicked
 * @return {void}
 */
const onTabbarChange = (index) => {
    console.log("tabbar click ->" + index)
    switch (index) {
        case "Home":
            router.push({ name: "HomeView" })
            break
        case "Category":
            router.push({ name: "CategoryView" })
            break
        case "Cart":
            router.push({ name: "CartView" })
            break
        case "My":
            router.push({ name: "MyView" })
            break
    }
    // setTimeout(() => {
    // store.commit('clearPageDepth')
    // }, 200);
}
</script>

<style>

.TabbarCom .van-badge {
    font-size: 16px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 1px;
    padding-bottom: 1px;
}

.TabbarCom .van-tabbar-item__icon .van-icon  {
    font-weight: 800;
}

.TabbarCom .van-tabbar-item__text {
    font-weight: 800;
}

</style>
